import React from "react"
import Layout from "../components/layout"

import Sezione from "../components/sezione"
import SEO from "../components/seo"
import CardPagina from "../components/cardPagina"
import BanneronePage from "../components/banneronePage"
import TitoloPagina from "../components/titoloPagina"
import BottoneBianco from "../components/bottoneBianco"
import { Link } from "gatsby"

import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  Tab,
  ListGroup,
  Tabs,
} from "react-bootstrap"

const ServiziIngegneria = () => (
  <Layout isPagina={true}>
    <SEO
      title="Servizi di ingegneria informatica ed elettronica in tutto il lazio"
      description="Servizi di ingegneria informatica ed elettronica, produzione di reti e monitoraggio elettromagnetico a Roma, Aprilia, Pomezia, Latina, Anzio e Nettuno"
    />
    <BanneronePage title="Servizi di Ingegneria" />
    <Container className="mt-4 mb-4">
      <TitoloPagina title="Servizi di Ingegneria Lazio: misure di campo elettromagnetico, elettrosmog, consulenza energetica, energy management, ristrutturazioni edilizie, riqualificazioni energetiche" />
      <CardPagina variante="bordoTop bordoBottom">
        <p>
          Nel settore dei <u>Servizi di Ingegneria</u>, l'azienda si avvale
          della competenza ed esperienza di ingegneri elettronici e delle
          telecomunicazioni per la fornitura di:
        </p>
        <ul>
          <li>Servizi ICT aziendali e formazione</li>
          <li>
            Consulenza e progettazione di reti tlc aziendali (LAN, WAN, INFOSEC,
            monitoring, Security, etc)
          </li>
        </ul>
        <Row>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/servizi-di-ingegneria-security.jpg"
              alt="Progettazione reti aziendali Lazio"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/servizi-di-ingegneria-reti-aziendali.jpg"
              alt="Progettazione reti aziendali Anzio Nettuno"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/servizi-di-ingegneria-formazione.jpg"
              alt="Formazione reti aziendali Lazio"
            />
          </Col>
        </Row>
        <ul>
          <li>
            Monitoring elettromagnetico per la valutazione dei livelli di campo
            elettromagnetico - rilievi di radiazioni non ionizzanti (NIR) in
            tutta la regione Lazio ai fini della salvaguardia della salute umana
            (Dlgs 81/08)
          </li>
        </ul>
        <Row>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/rilievi-nir-lazio.jpg"
              alt="Rilievi NIR Regione Lazio"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/radiazioni-nir-lazio.jpg"
              alt="Radiazioni NIR regione lazio"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/NIR.jpg"
              alt="NIR"
            />
          </Col>
        </Row>
        <p>
          Grazie alla consolidata esperienza e competenza di ingegneri edili
          strutturisti, NSE srl offre servizi di progettazione, realizzazione e
          collaudo di strutture in legno lamellare ed acciaio sedi di impianti
          di generazione fotovoltaica o termica.
        </p>
        <Row>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/pensilina-fotovoltaica-in-legno.jpg"
              alt="Pensiline fotovoltaiche in legno Lazio"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/pensilina-fotovoltaica-in-legno-lazio.jpg"
              alt="Pensiline fotovoltaiche in legno Anzio"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/progettazione-tetti.jpg"
              alt="Progetto Tetti"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/struttura-legno.jpg"
              alt="Strutture in Legno Lazio"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid my-auto"
              src="../servIng/struttura-legno-lazio.png"
              alt="Strutture in legno Anzio Nettuno"
            />
          </Col>
          <Col sm={4}>
            <img
              className="rounded mt-1 img-fluid "
              src="../servIng/realizzazione-legno.jpg"
              alt="Realizzazioni in legno Lazio"
            />
          </Col>
        </Row>
      </CardPagina>
      <Sezione>
        <CardPagina variante="white bordoTop mt-5">
          <Row>
            <Col xs={12} className="mt-3">
              <p className="titoloGrande text-center gridParity">
                Non esitare a contattarci per una consulenza ed un preventivo gratuito
              </p>
            </Col>
            <Col xs={12}>
              <BottoneBianco
                className="mt-3 mx-auto text-center variantePage"
                title="Contattaci per informazioni e preventivi"
                link="richiesta-informazioni"
              />
            </Col>
          </Row>
        </CardPagina>
      </Sezione>
    </Container>
  </Layout>
)

export default ServiziIngegneria
